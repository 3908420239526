.panel-row {
    margin: @content-padding 0 0 0;
    position: relative;
    &--padding {
        margin: 0;
        padding: @content-padding 0;
    }
    &--grey {
        background-color: @c-grey;
    }
    &--gradient {
        padding-top: @content-padding + 10px;
        .gradient-strip;
    }
}

.panel-col {
    display: flex;
}

.panel {
    background-color: #FFF;
    border: 0;
    border-radius: 0;
    margin: 0 0 30px 0;
    flex: 1;
    position: relative;
    border: 2px solid @c-grey;
    display: flex;
    max-width: 100%;
    .transition(.3s);

    &--gradient {
        .panel-body {
            padding-bottom: @panel-padding + 7px;
        }

        &:after {
            content: '';
            position: absolute;
            right: -2px;
            bottom: -2px;
            left: -2px;
            height: 7px;
            background: linear-gradient(90deg, @c-secondary 0%, @c-main 100%);
        }
    }

    &--events {
        border: 2px solid @c-blue--light;
        flex-direction: column;
    }

    &--colour {
        border: 0;
    }

    &--icon {
        padding-right: 80px;
    }

    &--image-icon {
        .panel__icon {
            top: @panel-padding + 20px;
            right: @panel-padding + 20px;
        }
    }
}

.panel__text{
    width: 100%; 
}
.panel-body {
    padding: @panel-padding;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    // word-break: break-all;
    &--block {
        display: block;
    }
    p {
        width: 100%;
    }
}

.panel-title {
    color: @c-main;
    font-weight: 500;
    font-size: 1.3rem;
    margin: 20px 0;
    border-bottom: 3px solid @c-secondary;
    display: inline-block;
    width: 100%; 

}

.panel__img-container {
 //   margin-bottom: 15px;
    width: 100%;
    max-width: 100%;
    // flex: 1 0 100%;
    // height: auto !important;
}

.panel__img {
    width: 100%;
}

.panel__link {
    color: @c-main;
    font-weight: 500;
    margin-top: auto;
    display: block;
    width: 100%;
    // flex: 0 1 100%;
    &:after {
        content: '\f138';
        font-family: 'fontAwesome';
        font-size: 1.3rem;
        color: @c-secondary;
        margin-left: 10px;
        display: inline-block;
        transform: translateY(2px);
    }
    &--no-icon {
        &:after {
            display: none;
        }
    }
    &:empty {
        &:after {
            display: none;
        }
    }
}

.panel__list-group {
    margin: 0;
    width: 100%;
    list-style-type: none;
    padding: 0;
}

.panel__list-item {
    border: 0;
    border-bottom: 2px solid @c-secondary;
    margin:0;
    padding: 0;
    border-radius: 0 !important;
    &:first-of-type {
        .panel__list-link {
            padding-top: 0;
        }
    }
    &:last-of-type {
        border-bottom: 0;
        .panel__list-link {
            padding-bottom: 0;
        }
    }
}

.panel__list-link {
    padding: 10px 0;
    display: block;
    color: @c-blue--text;
    // font-weight: 500;
}

.panel__icon {
    position: absolute;
    top: @panel-padding;
    right: @panel-padding;
    font-size: 2rem !important;
    width: 60px;
    height: 60px;
    background-color: @c-secondary;
    border-radius: 50%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: #FFF;
}