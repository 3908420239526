.nested-panel {
    width: 100%;
    margin: 0;
    padding: 50px 0;

    @media @tablet {
        padding: 20px 0;
    }

    &--navigation-block {
    }

    &--feature-panels {
    }

    &--featured-content {
        margin: 2.5px 0;
        padding: 0;
        /*&:last-of-type{
            margin-bottom: 20px;
        }

        &:first-of-type{
            margin-top: 20px;
        }*/
    }

    &--cta-block {
        background-color: @c-grey--light;
        height: 800px;
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;

        @media @tablet {
            height: auto;
        }
    }

    &--text-block {
        //padding: 80px 0;
    }

    &--media-blocks {
    }

    &--latest-news {
        background-color: @c-green--light;
        color: #fff;
        position: relative;
    }

    &--related-content {
        background-color: @c-green--light;
        position: relative;
    }

    &--interest-form {
        position: relative;
    }
    &--homepage-block{
        padding-top: 30px;
        padding-bottom: 0;
    }
}
.nested-panel__main-link {
    align-items: center;
    background: @c-blue--dark;
    color: #fff;
    display: flex;
    font-size: .75rem;
    font-weight: 700;
    justify-content: center;
    margin: 0 auto;
    margin-top: 50px;
    min-height: 40px;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 180px;

    &:hover
        {
        color: #fff;
    }
}
.nested-panel__strip {
    background-color: #7ac143;
    height: 11px;
    width: 100%;
}
.remove-strip{
    width: 100%; 
    .nested-panel{
        padding-bottom: 0;
    }

    .featured-content__container{
        padding-bottom: 0;
    }
}