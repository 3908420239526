// @font-face {
//   font-family: 'Basis';
//   src: url('/fonts/BasisGrotesqueMonoPro-Light.woff2') format('woff2'), url('/fonts/BasisGrotesqueMonoPro-Light.woff') format('woff'), url('/fonts/BasisGrotesqueMonoPro-Regular.ttf') format('truetype');
//   font-weight: 300;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Basis';
//   src: url('/fonts/BasisGrotesqueMonoPro-Regular.woff2') format('woff2'), url('/fonts/BasisGrotesqueMonoPro-Regular.woff') format('woff'), url('/fonts/BasisGrotesqueMonoPro-Regular.ttf') format('truetype');
//   font-weight: 400;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Basis';
//   src: url('/fonts/BasisGrotesqueMonoPro-Bold.woff2') format('woff2'), url('/fonts/BasisGrotesqueMonoPro-Bold.woff') format('woff'), url('/fonts/BasisGrotesqueMonoPro-Bold.ttf') format('truetype');
//   font-weight: 800;
//   font-display: swap;
// }

h1,
h2,
h3,
h4,
h5 {
    margin: 30px 0;
    font-weight: 300;
    line-height: 1.4;

    &:first-of-type {
        margin-top: 0;
    }
    &:empty {
        margin: 0;
    }
}

h1 {
    font-size: 2.4rem;
    font-weight: 400;
}

h2{
    
    margin-bottom:20px; 
    color: @c-blue--dark;
    font-weight: 600;
    font-size: 2.5rem;
    border-bottom: solid @c-main 5px;
    padding-bottom: 15px;
    display: inline-block;

    @media @mobile{
        font-size: 1.8rem;
    }
   
    /*margin: 20px 0 10px 0;*/
    
}

h3 {
    font-size: 1.875rem;
    font-weight: 700;
    border-bottom: solid @c-main 2.5px;
    display: inline-block;
    padding-bottom: 5px;
    color: @c-blue--dark;
    margin-bottom: 20px;
}
h4 {
    color:  @c-blue--dark;
}
p {
    margin: 30px 0;
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 0;
    // font-weight: 200;
    &:first-of-type {
        margin-top: 0;
    }
    &:empty {
        margin: 0;
    }
    span {
        font-size: inherit;
        line-height: inherit;
    }
    strong {
        font-weight: 600;
    }
    /*&:last-of-type{
        margin-bottom:0;
    }*/
}

.intro-text {
    font-size: 1.625rem;
    line-height: 1.3;

    p {
        font-size: 1.625rem;
        line-height: 1.3;

        &:last-of-type{
            margin-bottom: 0;
        }
    }
}
.Subheading {
   color: @c-blue--text;
   font-weight: 800;
   font-size: 1.25rem;
   border-bottom: @c-main solid 3px;
   display: inline-block;

}


li{
    margin: 10px 0;
    font-size: 1rem; 
}