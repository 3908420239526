.header__search-container {
    position: relative;
    z-index: 101;
    display: flex;
    flex: 0 0 ~"calc(75% - 15px)";

    @media @tablet {
        flex: unset; 
    }
}

.header__search-form {
    // width: 273px;
    display: flex;
    flex: 1;
    @media @tablet {
        flex: unset;
        position: absolute;
        top: 60px;
        right: 0;
        background-color: #FFF;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transform: translateY(-10px);
        .transition(.2s);
        &:before {
            content: '';
            height: 15px;
            width: 15px;
            background-color: #FFF;
            transform: rotate(45deg);
            position: absolute;
            top: -5px;
            right: 15px;
            z-index: -1;
        }
        &--active {
            visibility: visible;
            opacity: 1;
            pointer-events: all;
            transform: translateY(0);
        }
    }
}

.header__search-input {
    border: 1px solid #ddd;
    padding: 8px 12px;
    flex: 1;
    height: 42px;

    @media @tablet{
        flex: unset;
    }
}

.header__search-submit {
    border: 0;
    padding: 8px 12px;
    background-color: @c-green--medium;
    color: @c-blue--text;
    font-weight: 500;
    margin-left: -1px;
    font-size: .9rem;
    position: relative;
    text-indent: -999999px;
    overflow: hidden;
    height: 42px;
    width: 42px;
    .transition(.2s);
    &:after {
        content: '\f002';
        font-family: 'fontAwesome';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-indent: 0;
    }
    &:hover {
        background-color: lighten(@c-main, 15%);
    }
}

.search-trigger {
    height: 37px;
    width: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
    user-select: none;
    margin-bottom: 0;

    &:after {
        content: '\f002';
        font-family: fontAwesome;
    }

    @media @tablet-min {
        display: none;
    }
}

.search-box {
    background-color: #f5f5f5;
    padding: 30px;
    margin-bottom: 30px;
}

.search-box__title {
    margin-bottom: 20px;
}

.search-box__form {
    display: flex;
    flex-wrap: wrap
}

.search-box__button {
    .button;
    margin: 0 0 0 10px;
    margin-left:0; 
    width:25%;
    margin-right:15px; 
    @media @tablet {
        margin: 10px 10px 0 0;
    }
    &--location {
        background-color: @c-secondary;
        width:60%;
    }
}

.search-box__input {
    padding: 5px 10px;
    width:100%;
    margin-bottom:10px;
    @media @tablet {
        flex: 0 1 100%;
    }
}

