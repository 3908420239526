.main-banner {
  //  background-color: @c-green--medium;
   // background-image: linear-gradient(to right, #14628b, #007593, #00868c, #009578, #4fa15d);
    background-size: cover;
    background-position: 80%;
    color: #FFF;
    position: relative;
    height: 310px;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 200px; 
     //.gradient-background;
    // .gradient-strip;
    // .background-texture;
    a {
        color: @c-blue--text;
    }
}
.homePage {
    .main-banner {
        height: 310px;
    }

    .main-banner__title {
      //  border: 6px solid;
    }
}
.gatewayPage,.standardContentPage,.publication {
    .main-banner {
        height: 200px;
    }

    .main-banner__title {
        border: none;
    }
}
/*.standardContentPage{
    .main-banner{
        height: 200px;
    }
    .main-banner__title{
        border: none;
    }
}*/
/*.gatewayPage{
    .main-banner{
        height:250px;
    }
}
.standardContentPage{
    .main-banner{
       height:160px;
    }
}*/

.main-banner__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-image: linear-gradient(to right, #14628b, #007593, #00868c, #009578, #4fa15d);
}
.main-banner__background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: cover;
    z-index: 2;
}
.main-banner__container {
    position: relative;
    /*padding-top: 170px;
    padding-bottom: 80px;*/
    display: flex;
    flex-direction: column;
    /*@media @mobile{
         padding-top: 90px;
    }*/
}

.main-banner__content-container {
    position: relative;
    z-index: 2;

    @media @tablet-min {
        /*max-width: 600px;*/
    }
}

.main-banner__title {
    font-size: 3rem;
    line-height: 1.2;
    text-align: center;
    // border: 6px solid;
    padding: 0 35px;
    width: 690px;
    margin: 0 auto;
    color: #fff;
    font-weight: 400;
    display: block;
    // padding-bottom: 0;
    @media @tablet {
        font-size: 1.8rem;
        width: 430px;
    }

    @media @mobile {
        font-size: 1.3rem;
        width: 290px;
        padding: 15px;
    }
}

.main-banner__text {
    font-size: 1.3125rem;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    

    p {
        font-size: inherit;
        margin: 20px 0;
        font-weight: 600;
        line-height: 1.35;
        margin-top: 10px;
    }
}

.main-banner__link {
    // color: @c-yellow;
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: 0.75rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    background: @c-green--medium;
    padding: 10px;
    width: 180px;
    display: flex;
    justify-content: center; 
    align-items: center;
    margin: 0 auto;
    min-height: 40px;
    margin-top: 20px;
    /*&:hover {
        text-decoration: none;

        &:before {
            transform: scaleX(1);
        }

        &:after {
            transform: scale(.8);
        }
    }*/

    &:before {
        content: '';
        position: absolute;
        bottom: -5px;
        right: 0;
        left: 0;
        border-bottom: 2px solid @c-yellow;
        transform: scaleX(0);
        transform-origin: left;
        will-change: transform;
        .transition(.2s);
    }

    /*&:after {
        content: '\f138';
        font-family: 'fontAwesome';
        margin-left: 10px;
        font-size: 1.5rem;
        will-change: transform;
        .transition(.2s);
    }*/
}


.main-banner__img {
    position: absolute;
    // top: -10px;
    top: 50%;
    transform: translateY(-46%);
    right: 0;
    bottom: 0;
    width: 100%;
    // width: 50%;
    max-width: 500px;
    // height: 100%;
    // object-fit: cover;
    z-index: 1;
    // opacity: .5;
    @media @tablet {
        display: none;
    }
}
.print-title{
    display:none;
}
.print-text{
    display:none;
}

.gatewayPage {
    .main-banner__img {
        top: 66%;
    }
}