.search-results__list {
    margin: 0 0 30px;
    padding: 0;
    list-style-type: none;
}


.search__filter-dropdown-container {
    /* width: 50%; */
    display: inline;
}

.search-results__item {
    border-bottom: 2px solid #eee;
    padding: 10px 0;
    position: relative;
    text-align: left;

    // display: flex;
    // justify-content: space-between;
    // &:before {
    //     content: '';
    //     position: absolute;
    //     top: -2px;
    //     right: -20px;
    //     bottom: -2px;
    //     left: -20px;
    //     background-color: #eee;
    //     opacity: 0;
    //     z-index: -1;
    //     .transition(.3s);
    // }
    // &:hover {
    //     &:before {
    //         opacity: 1;
    //     }
    // }
    // }

    .search-result__date {
        margin-bottom: 5px;
    }

    &:last-of-type {
        border-bottom: 0;
    }
}

.search-results__title {
    margin-bottom: 10px;
    font-size: 1.2rem;
    max-width: 95%;

    @media @tablet{
        max-width: 90%;
        font-size: 1rem;
    }

    @media @mobile{
        max-width: 85%;
    }
    &:after {
        color: #14518a;
        content: '\f138';
        display: inline-block;
        font-family: fontAwesome;
        font-size: 2rem;
        margin-left: 10px;
        position: absolute;
        right: 0;
    }

    &--file {
        &:after {
            color: #14518a;
            content: '\f15b';
            display: inline-block;
            font-family: fontAwesome;
            font-size: 2rem;
            margin-left: 10px;
            position: absolute;
            right: 0;
        }
    }
}
/*.search-results__title--file {
    margin-bottom: 10px;
    font-size: 1.2rem;
    max-width: 95%;


}*/
.search-results__score {
    font-weight: 500;
}

.search-abstract {

 
}
.simple-pagination-showing-x-of-x {
    text-align: right;
    display: block;
}
.paginationBottomHolder {
    font-size: 16px;
    width:100%;
}

.pagination-left {
    width: 25%;
    float: left;
}

.pagination-center {
    width: 50%;
    float: left;
    text-align: center;
}

.pagination-right {
    width: 25%;
    float: left;
    text-align: right;
}

.search-pagination {
    display: inline-block;
    margin-right: 15px;

    &:last-child {
        margin: 0;
    }
}
.searchResults{
    padding:0 15px;
    width:100%;
}
.search-results__link{
    display: block;
    color: @c-blue--text;

    max-width: 95%;

    @media @tablet{
        max-width: 90%;

    }

    @media @mobile{
        max-width: 85%; 
    }
}


/* filter sidebar*/

.search__search-box {
    background-color: #eee;
    padding: 20px;
    width: 100%;
    margin-bottom: 30px;
}

.search__label {
    font-weight: 500;
    //  margin-bottom: 10px;
    display: block;
    font-size: .9rem;
}

.search__input-container {
    position: relative;
}

.search__input {
    border: 1px solid #eee;
    padding: 15px;
    margin-bottom: 10px;
    width: 100%;
    background-color: #fff;

    &--date {
        width: 100%;
        border-radius: 0;
    }
}

.search__input-clear {
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;

    &--show {
        display: block;
    }
}

.search__buttons {
    display: flex;
    margin-bottom: -10px; //account for button margin
}