.breadcrumb {
    background: none;
    padding: 0;
    margin: 0;
}

.breadcrumb__item {
    margin: 5px 0;
    font-size: 1rem;
    &:not(:first-of-type) {
        &:before {
            content: '\f054' !important;
            font-family: 'fontAwesome';
            color: @c-blue--text;
            font-weight: 400;
            font-size: .7em;
            padding: 0 10px;
        }
    }
    a{
        font-size: 1rem;
    }
}

.breadcrumb__link {
    color: @c-blue--text;
    font-weight: 500;
    &:hover {
        color: @c-blue--text;
    }
}
