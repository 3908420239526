header {
    padding: 20px 0;
    z-index: 101;
    position: relative;
    background-color: #FFF;
    @media @tablet {
        padding: 10px 0;
        position: sticky;
        top: 0;
        border-bottom: 1px solid #eee;
        height: @header-height--tablet;
    }
}

.header__container {
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    margin-right: 15px;
    flex: 0 0 25%;

    @media @tablet {
        margin-right: auto;
        flex: unset;
    }
}

.logo__img {
    /*width: 270px;*/
    height: 55px;
    will-change: transform;
    .transition(.3s);
    width: 285px;
    @media @tablet-min {
        &:hover {
            transform: scale(.98);
        }
    }
    @media @tablet {
        max-width: 220px;
        width: 100%;
        padding-right: 20px;
    }
    @media @mobile {
        max-width: 190px;
    }
}

.header__accessibility.button {
    margin-bottom: 0;
    margin-right: 0;
    margin-left: 10px;
    height: 42px;
    @media @tablet {
        margin-right: 10px;
        margin-left: 0;
    }

    min-width: 0;

    &:before {
        content: '\f29a';
        font-family: fontAwesome;
        font-size: 1rem;
        margin-right: 10px;
    }

    @media @tablet {
        width: 37px;
        height: 37px;
        padding: 5px;
        border-radius: 4px;

        &:before {
            margin: 0;
        }

        span {
            display: none;
        }
    }
}
