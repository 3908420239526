.publication-listing__filter-panel {
    background-color: @c-green--medium;
    height: 180px;
    margin-bottom: 40px;
    padding-bottom: 10px;
    /*background-image: url("/img/PanelOverlay-Cropped.png");
    background-size: cover;
    background-position: center;*/
    position: relative;
    z-index: 1;

    @media @tablet {
        height: auto;
        padding: 20px 0;
    }

    label {
        color: @c-blue--text;
        font-weight: 700;
    }
}
.publication-listing__filter-overlay {
    background-image: url("/img/PanelOverlay-Cropped.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2; 
}
.publication-listing__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    @media @tablet {
        display: block;
    }

    select {
        height: 35px;
        font-size: 1rem;
        padding: 0 5px;
        width: 250px;
        z-index: 5;
        margin-left: 50px;

        &#publicationStatus {
            margin-left: 30px;
        }

        @media @tablet {
            margin-left: 0;
        }
    }

    input[type=date] {
        height: 35px;
        color: @c-blue--text;
        z-index: 5;

        @media @mobile {
            font-size: .9rem;
        }
    }

    input[type=text] {
        width: 250px;
        margin-left: 2em;
        color: @c-blue--text;
        z-index: 5;


        @media @mobile {
            font-size: .9rem;
        }
    }
}


#publicationKeyword {
    margin-left: 6px;
    width: 600px;
    height: 2rem;
    max-width: 100%;
}

.publication-listing__listing-panel {
    margin-bottom: 40px;

    /*@media @tablet {
        overflow-x: scroll;
    }*/

    .row{
        &:before{
            content: none;
        }
    }
    &--page {
        .publication-listing__headings, .publication-listing__item {
            flex-wrap: wrap;
            &:after{
                border: none;
            }
               
        }


    }
}
.publication-listing__headings{
    margin: 0 -10px; 

    @media @tablet{
       display: none;
    }
    .publication-listing__col{
        @media @tablet
    {
            border: none;
        }
    }
    
}
.publication-listing__heading {
    background-color: @c-green--medium;
    color: @c-blue--text;
    padding: 10px;
    font-size: 1.125rem;
    font-weight: 700;
   height: 38px;
    display: flex;
    align-items: center;

    @media @mobile{
        margin: 0;
    }
    //  margin-left: 15px;
}
.publication-listing__col {
    padding: 0 10px;


    &:not(:last-of-type) {
        border-right: solid 1px grey;

        @media @tablet {
            border: none;
        }
    }



    &--title {
        // width: 33%;
        flex: 0 0 32%;

        @media @mobile {
            flex: 0 0 95%;
        }
    }

    &--ref {
        //  width: 19%;
        flex: 0 0 20%;

        @media @mobile {
            flex: 0 0 70%;
        }
    }

    &--specialty {
        //  width: 19%;
        flex: 0 0 20%;

        @media @mobile {
            flex: 0 0 50%;
        }
    }

    &--date {
        // width: 13%;
        flex: 0 0 17%;

        @media @tablet {
            flex: 0 0 20%;
            margin-top: 5px;
        }

        @media @mobile {
            flex: 0 0 50%;
        }
    }

    &--url {
        //width: 7%;
        flex: 0 0 9%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-end;


        a {
            min-width: 100px;
            margin: 0;
        }
        @media @tablet{
             display: block;
             margin-top: 10px;
        }
        @media @mobile {
            flex: 0 0 30%;
        }
    }

    /*@media @tablet {
        border-bottom: solid 1px #929497;
    }*/
}
.publication-listing__list {
}
.publication-listing__item {
    font-size: 1rem;
    color: @c-blue--text;
    //  border-bottom: solid #bbbdbf 2px;
    padding: 5px 0;
    margin: 0 -10px;

    &:first-of-type {
        padding-top: 0;
    }

    &:after {
        content: '';
        border-bottom: solid 2px #929497;
        margin: 0 10px;
        height: 10px;
        width: 100%;

        @media @tablet{
            width: 97%;
        }
        @media @mobile{
            width: 95%;
        }
    }

    /*@media @tablet {
        flex-wrap: nowrap;
     
    }*/
    @media @tablet{
        display: block;
    }
}
.publication-listing__item-title{
    font-weight: 600;

    @media @mobile{
        padding: 10px 0;
    }
   // margin-left: -10px;
}
.publication-listing__item-type {

 // padding: 0 10px;
}
.publication-listing__item-topic {
   //padding: 0 10px;
}
.publication-listing__item-date {
  // padding: 0 15px;
  margin-left: 5px;
}
.filter-container {
    height: 100%;
    display: flex;
  //  align-items: center;
    flex-direction: column;
    justify-content: unset;
    z-index: 3;

    
}
.filter-container__dates{
    display: flex; 

}

.publication-listing__filters {
    #statusLabel{
        margin-left: 77px;

        @media @tablet{
            margin-left: 0;
        }
    }
    .filter-container{
        align-items: flex-end;

        @media @tablet{
            align-items: flex-start;
        }
        &--status{
            align-items: flex-start;
        }
    }
    .filter-container__dates{
        margin-left: 30px;

        @media @tablet{
            margin-left: 0;
        }

        @media @mobile{
            display: block;
        }
    }

    /*.filter-container__dates {
        display: block;
        .date-divider{
            opacity: 0;
        }
    }*/
    .filter-button{
        margin-top: 72px;

        @media @tablet{
            margin-top: 25px;
        }
    }
   
}
.publications-listing__button-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 25px;
}
.publication-listing__button{
    min-width: 80px;
}
.filter-button {
    font-weight: 700;
    border: solid 4px;
    margin-top: 17px;
    background-color: transparent;
  z-index: 5;
}
.filter-divider{
    display: flex;
    margin-top: 20px;

    @media @tablet{
        flex-direction: column;
    }
}