.services__panel {
    @media @tablet {
        flex-direction: column;
    }
    /*&.major {
        &:before {
            content: '\f06a';
            font-family: 'fontAwesome';
            position: absolute;
            top: 7px;
            left: 20px;
            color: #FFF;
            z-index: 2;
        }
        &:after {
            content: 'Major';
            text-transform: uppercase;
            background-color: @c-main;
            position: absolute;
            top: -2px;
            left: -2px;
            padding: 10px 20px 10px 45px;
            font-weight: 500;
            color: #FFF;
            border-radius: 0 0 5px 0;
            font-size: .9rem;
        }
        .panel-body {
            padding-top: 55px;
        }
    }*/
    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    h3, p {
        margin: 15px 0;
    }
}

.services__title {
    margin-bottom: 10px;
    margin-top:0 !important;
}

.services__sub-title {
    font-size: 1.2rem;
    font-weight: 400;

    &.major {
        /*&:before {
            content: '\f06a';
            font-family: 'fontAwesome';
            
            color: #FFF;
            z-index: 2;
        }*/

        &:after {
            content: '\f06a   Major';
            font-family: FontAwesome;
            text-transform: uppercase;
            background-color: @c-main;
            /*position: absolute;
            top: -2px;
            left: -2px;*/
            margin-left: 10px;
            padding: 10px;
            font-weight: 500;
            color: #FFF;
            /*border-radius: 5px;*/
            font-size: .9rem;
        }
    }
}

.services__img-container {
    // height: 100%;
    width: 300px;
    background-color: #eee;
    position: relative;
    @media @tablet {
        width: 100%;
        // height: 200px;
        padding-top: 50%;
    }
}

.services__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // display: block;
}

.services__filters {
    @media @desktop {
        margin-top: 20px;
    }
}
.service-major {
    &:before {
        content: '\f06a';
        font-family: 'fontAwesome';
        color: #FFF;
        z-index: 2;
    }

    text-transform: uppercase;
    background-color: @c-main;
    margin-left: 5px;
    padding: 10px 20px 10px 20px;
    font-weight: 500;
    color: #FFF;
    font-size: .9rem;
}