.media-block__intro{
    text-align: center;
    margin-bottom: 75px;
}
h2.media-block__title {
    margin-bottom: 0;
}
.media-block__subtitle {
    margin: 0 auto;
    margin-top: 50px;
    max-width: 900px;

    p {
        margin: 0 auto;
        max-width: 900px;
        font-size: 1.625rem;
        font-size: 600;
    }
}
.media-blocks {
    //margin-top: 75px;
}
.media-block__list{
    /*display: flex; 
    flex-wrap: wrap;*/ 
    
}
.media-block__item-row {
    display: flex;
    position: relative;
    justify-content: center;

    @media @tablet {
        display: block;
    }

    &:nth-of-type(odd) {
        .media-block__item {
            &:nth-of-type(1) {
                background: @c-blue--dark;

                .media-block__content, .media-block__content-title {
                    color: #fff;
                }

                .media-block__link {
                    color: #fff;
                    border: solid #fff 4px;
                }
            }

            &:nth-of-type(2) {
                background: @c-green--light;
            }

            &:nth-of-type(3) {
                background: @c-blue--light;
            }
        }
    }

    &:nth-of-type(even) {
        .media-block__item {
            &:nth-of-type(1) {
                background: @c-blue--light;
            }

            &:nth-of-type(2) {
                background: @c-blue--dark;

                .media-block__content, .media-block__content-title {
                    color: #fff;
                }

                .media-block__link {
                    color: #fff;
                    border: solid #fff 4px;
                }
            }

            &:nth-of-type(3) {
                background: @c-green--light;
            }


            @media @tablet {
                &:nth-of-type(1) {
                    background: @c-blue--dark;

                    .media-block__content, .media-block__content-title {
                        color: #fff;
                    }

                    .media-block__link {
                        color: #fff;
                        border: solid #fff 4px;
                    }
                }

                &:nth-of-type(2) {
                    background: @c-green--light;
                }

                &:nth-of-type(3) {
                    background: @c-blue--light;
                }
            }
        }
    }
}
.media-block__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    //   background-image: url("/img/Placeholders/TestImage.png");
    background-image: url("/img/PanelOverlay-Cropped.png");
    background-size: cover;
    background-position: center;
}
.media-block__item {
    flex: 1 0 33.3333%;
    background-color: @c-blue--dark;
    padding: 30px;
    display: flex;
    position: relative;
   // min-height: 363px;
 
    @media @tablet{
         flex: 0 0 100%;
         margin-top: 10px;
    }
} 
.media-block__content {
    text-align: center;
    color: @c-blue--text;
    display: flex;
    flex-direction: column; 
    z-index: 2;
    width: 100%;
} 
.media-block__icon{
    height: 50px;
    margin: 0 auto;
    margin-bottom: 30px;

}
.media-block__content-title {
    font-weight: 700;
    line-height: 1.2;
    color: @c-blue--text;
    border: none;
    font-size: 1.875rem;
   
}
.media-block__text{
    //margin-bottom: 40px;
    p{

    }
}
.media-block__link {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: 0.75rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    border: solid @c-blue--text 4px;
    color: @c-blue--text;
    padding: 10px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-height: 40px;
    margin-top: auto;

    &:hover{
        background-color: #fff;
        color: @c-blue--dark;
    }
}
.media-block__subtitle {
    h3{
        a{
            position: relative; 
            top: -100px;
        }
    }
}