.latest-news__intro{
    text-align: center;
}
h2.latest-news__title {
 //   margin-bottom: 50px;
    margin-bottom: 0px;
}
.latest-news__subtitle {
    margin: 0 auto;
    margin-top: 50px;
    max-width: 900px;
    color: @c-blue--text;

    p {
        margin: 0 auto;
        max-width: 900px;
        font-size: 1.625rem;
        font-size: 600;
        color: @c-blue--text;
    }
}
.latest-news{
   // margin-top: 80px;
}
.latest-news__list{
    display: flex; 
    flex-wrap: wrap; 
     
}


.latest-news__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    //   background-image: url("/img/Placeholders/TestImage.png");
    background-image: url("/img/PanelOverlay-Cropped.png");
    background-size: cover;
    background-position: center;
}
.latest-news__item {
    flex: 1 0 33.3333%;
 //   background-color: @c-green--light;
    padding: 30px;
    display: flex;
    margin-top: 60px;
    // min-height: 363px;
    &:not(:nth-child(3n + 3)) {
        border-right: solid 2px #fff;

        @media @tablet {
            border: none;
        }
    }

    &:last-of-type {
        border: none;
    }


    @media @tablet {
        flex: 0 0 50%;
        margin-top: 10px;
    }

    @media @mobile {
        flex: 0 0 100%;

    }
} 

.latest-news__content {
    text-align: center;
    color: @c-blue--text;
    display: flex;
    flex-direction: column; 
    z-index: 2;
    width: 100%;
} 
.latest-news__icon{
    height: 50px;
    margin: 0 auto;
    margin-bottom: 30px;

}
.latest-news__content-title {
    font-weight: 700;
    line-height: 1.2;
    color: @c-blue--text;
    border: none;
    font-size: 1.5rem;
}
.latest-news__date {
    background-color: @c-blue--dark;
    color: #fff;
    font-weight: 700;
    font-size: 0.875rem;
    width: 140px;
    height: 28px;
    display: flex;
    justify-content: center; 
    align-items: center; 
    margin: 0 auto;
    margin-bottom: 30px;
}
.latest-news__text {
    margin-bottom: 40px;

    p {
        font-size: 0.875rem;
    }
}
.latest-news__link {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: 0.75rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    border: solid @c-blue--text 4px;
    color: @c-blue--text;
    padding: 10px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-height: 40px;
    margin-top: auto;

    &:hover{
        background-color: #fff;
        color: @c-blue--dark;
    }
}
.latest-news__title
{
    color: @c-blue--text;
}
.latest-news__main-link {
 
    align-items: center;
    background: @c-blue--dark;
    color: #fff;
    display: flex;
    font-size: .75rem;
    font-weight: 700;
 
    justify-content: center;
    margin: 0 auto;
    margin-top: 50px;
    min-height: 40px;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 180px;
}