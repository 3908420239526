.map__popup {
    width: 100%;
    max-width: 250px;
    // height: 100%;
    // max-height: 250px;
    z-index: 2;
    position: absolute;
    display: none;
    background-color: #fff;
    border-radius: 6px;
    right: 250px;
    top: 203px;
    padding: 20px;
    box-shadow: 0 10px 30px 0 rgba(0,0,0,.5);
    /*overflow-y:auto;*/
    &:before {
        content: '';
        height: 15px;
        width: 15px;
        background-color: #FFF;
        transform: rotate(45deg);
        position: absolute;
        bottom: 20px;
        left: -5px;
        z-index: -1;
    }
    @media @mobile{
        position:absolute;
        top:100px;
        bottom:100px; 
        left:50px;
        right:50px;
        max-height:500px;
        max-width:none;
    }
}
.button__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    &:before {
        content: '\f00d';
        font-family: 'fontAwesome';
    }
}

    .map-panel{
        height:600px !important;
    }
