.text-block__content
{
    text-align: left;

    &--center-text{
        text-align: center;
    }
    p{
        font-size: 1.125rem;
        font-weight: 400; 

        &:last-of-type{
            margin-bottom: 0;
        }
    }
}
