.accordion-group {
    counter-reset: accordion-counter;
    position: relative;
}

.accordion {
    margin: 20px 0;
    &--steps {
        counter-increment: accordion-counter;
        margin-left: 40px;
        &:before {
            content: '';
            position: absolute;
            top: 16px;
            left: 14px;
            bottom: 16px;
            border-right: 2px solid #eee;
            z-index: 1;
        }
        .accordion__title {
            &:after {
                content: counter(accordion-counter);
                position: absolute;
                top: 15px;
                left: -40px;
                height: 30px;
                width: 30px;
                border-radius: 50%;
                border: 2px solid #eee;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                font-size: 1rem;
                background-color: #FFF;
                z-index: 2;
                color: @c-body;
            }
        }
    }
}

.accordion__title {
    position: relative;
    background-color: @c-main;
    margin: 0;
    padding: 13px 20px;
    font-size: 1.3rem;
    cursor: pointer;
    color: #FFF;
    .transition(.3s);
    border: none;
    &.collapsed {
        background-color: #eee;
        color: @c-body;
    }

}

.accordion__icon {
    margin-right: 10px;
}

.accordion__content {
    border: 2px solid #eee;
    border-top: 0;
    padding: 20px;
    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .panel {
        margin-bottom: 20px;
        &:last-of-type {
            margin: 0;
        }
    }
}

.accordion.feedback {
    .accordion__content {
        min-height: 470px;
        border-top: 2px solid #eee;
    }

    .accordion__title {
        //width: 250px;

        &:before {
            content: "\f075";
            font-family: FontAwesome;
        }

        @media @mobile {
            width: 100%;
        }
    }
}

.radiobuttonlist.dataconsent{
    border:none !important;
    box-shadow:none !important; 
    /*margin-bottom:20px;*/
}
.umbraco-forms-error-message {
    padding: .8em;
    margin-bottom: .5em;
    border: 2px solid #FBC2C4;
}

.umbraco-forms-error-message {
    background: #FBE3E4;
    color: #8a1f11;
}
.field-validation-error {
    color: #8a1f11; 
    display:block;
}
.input-validation-error {
    background: #FBE3E4;
}
.checkbox.consent{
    input{
        box-shadow:none !important;
        height:15px;
    }
}
.umbraco-forms-form.feedbackform {
    input.text {
        width: 83.3333% !important;
        padding: 5px;
    }

    textarea {
        width: 83.3333% !important;
        padding: 5px;
    }

    .umbraco-forms-field-wrapper{
        margin-bottom:10px;
    }
}

.feedback-panel{
    background-color: @c-blue--dark;
     color: #fff;
     text-align: center;
     padding: 40px 0;
}
.feedback-title {
    color: #fff;
    border-bottom: solid @c-main 4px;
    padding-bottom: 20px;
    font-weight: 600;
    font-size: 2.585rem;
}
.feedback-text {
    font-size: 1.625rem;
    font-weight: 200;
    margin-top: 20px;

    p {
        font-size: 1.625rem;
    }
}