.cta-block__image{

}
.cta-block__container {
    height: 100%;
}
.cta-block__content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.cta-block__text-block {
    border: solid #fff 5px;
    max-width: 400px;
    padding: 30px;
    min-height: 440px;
    position: relative;
    @media @mobile{
        min-height: 200px;
    }
    &:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -17px;
        top: 46%;
        border: 15px solid;
        border-color: #fff transparent transparent #fff;
        transform: rotate(-45deg);
        // opacity: .2;
        z-index: 1;
    }

    /*&:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -22px;
        top: 42%;
        border: 15px solid;
        border-color: #fff transparent transparent #fff;
      //  opacity: .2;
        transform: rotate(-45deg);
        z-index: 2;
    }*/
}
.cta-block__text {
    color: #fff;
    text-align: center;
    position: relative;

    p {
        font-weight: 600;
        font-size: 1.875rem;
        line-height: 1.1;
     //   background-color: @c-blue--dark;

        @media @mobile{
            font-size: 1.1rem;
        }
    }
}
.cta-block__link {

    align-items: center;
    background: @c-green--medium;
    display: flex;
    font-size: .75rem;
    font-weight: 700;
    justify-content: center;
    margin: 0 auto;
    margin-top: 50px;
    min-height: 40px;
    padding: 10px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 180px;
    color: @c-blue--text;
}
.cta-block__text-background{
    background: #000;
    opacity: .1;
    position: absolute; 
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @media @tablet{
        opacity: .6;
    }
}