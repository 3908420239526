.meetings-listing__filter-panel {
    background-color: @c-green--medium;
    height: 230px;
    margin-bottom: 40px;
    /*background-image: url("/img/PanelOverlay-Cropped.png");
    background-size: cover;
    background-position: center;*/
    position: relative;
    z-index: 1;

    @media @tablet {
        height: auto;
        padding: 20px 0;
    }

    @media @mobile {
        height: auto;
    }

    .filter-container {
        height: 100%;
        display: flex;
        //  align-items: center;
        flex-direction: column;
        justify-content: center;
        z-index: 3;

        &:nth-of-type(1) {
            width: 35%;
        }

        &:nth-of-type(3) {
            margin-left: auto;
        }
    }
}
.meetings-listing__filter-overlay {
    background-image: url("/img/PanelOverlay-Cropped.png");
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2; 
}
.meetings-listing__filters {
    display: flex;
    //justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    @media @tablet{
        display: block;
    }

    select {
        height: 35px;
        font-size: 1rem;
        padding: 0 5px;
        width: 365px;
        z-index: 5; 
        @media @mobile{
            width: 200px;
        }
    }

    input[type=date] {
        height: 35px;
        color: @c-blue--text;
        z-index: 5;

        @media @mobile{
             font-size: .9rem;
        }
    }
}
.meetings-listing__listing-panel{
    margin-bottom: 40px;

    @media @tablet{
        overflow-x: scroll;
    }
}
.meetings-listing__headings {
    margin: 0 -10px;

    @media @tablet {
     display: none;
    }

    .meetings-listing__col {
        @media @tablet {
            border: none;
        }
    }
}
.meetings-listing__col {
    padding: 0 10px;

    &:not(:last-of-type) {
        border-right: solid 1px grey;
        @media @tablet{
             border: none;
        }
    }

    &--title {
        flex: 0 0 50%;
        // width: 45%;
        @media @tablet {
            /*flex: 0 0 53%;*/
            margin-top: 15px;
        }

        @media @mobile {
            flex: 0 0 55%;
        }
    }

    &--type {
        //width: 25%;
        flex: 0 0 28%;

        @media @tablet{
            margin-top: 5px;
        }
        @media @mobile {
            flex: 0 0 55%;
        }
    }


    &--date {
        //width: 10%;
        flex: 0 0 10%;

        @media @tablet {
            flex: 0 0 12%;
        }

        @media @mobile {
            flex: 0 0 25%;
        }
    }

    &--url {
        width: 10%;
        flex: 0 0 10%;

        @media @tablet {
           margin-top: 10px;
        }
    }

    /*@media @tablet {
        border-bottom: solid 1px #929497;
    }*/
}
.meetings-listing__heading {
    background-color: @c-green--medium;
    color: @c-blue--text;
    padding: 10px;
    font-size: 1.125rem;
    font-weight: 700;
    height: 40px;
    
      //  margin-left: 15px;
    
}
.meetings-listing__heading-icon{
    width: 26px; 
    height: 26px;
    &--info{
        width: 20px;
        height: 20px;
    }
}
.meetings-listing__heading-container {
   background-color: @c-green--medium; 
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 10px;
   height: 40px;
  // min-height: 44px;

  &--info{
      min-width: 80px;
  }
  
}
.meetings-listing__list {
}
.meetings-listing__calendar-col {
    margin-left: -15px;

    @media @tablet{
         margin: 0 auto;
    }
}
.meetings-listing__item {
    font-size: 1rem;
    color: @c-blue--text;
    /*border-bottom: solid #bbbdbf 2px;*/
    // padding: 20px 0;
    margin: 0 -10px;
    padding: 5px 0;

    &:first-of-type {
        padding-top: 0;
    }

    @media @tablet {
        /*flex-wrap: nowrap;*/
        display: block;
    }


    &:after {
        content: '';
        border-bottom: solid 2px #929497;
        margin: 0 10px;
        height: 10px;
        width: 100%;
    }
}
.meetings-listing__item-title{
    font-weight: 600;
}
.meetings-listing__item-type {
  // margin-left: 15px;
}
.meetings-listing__item-topic {
 //   margin-left: 15px;
}
.meetings-listing__item-date {
    //margin-left: -10px;


}

.filter-container__dates{
    display: flex;

}
.meetings-listing__button-container{
    width: 100%; 
    display: flex; 
    justify-content: center;
    margin-top: 25px;
}

#calendar {
    width: 280px;
    margin-left: auto;
    background: none;
    border: solid 1px @c-blue--light;

    .clndr {
        overflow: hidden;
        //  padding: 20px;
        min-height: 380px;
    }

    .clndr-table {
        width: 90%;
        margin: 30px auto;


        td.day.last-month, td.day.next-month {
            visibility: hidden;
        }

        .day.event {
            .day-contents {
                background: @c-blue--light;
            }
        }

        .day.active {
            .day-contents {
                border: solid @c-blue--dark 2px;
            }
        }
    }

    .clndr-controls {
        display: flex;
        justify-content: space-between;
        background: @c-blue--light;
        color: @c-blue--text;
        height: 40px;
        align-items: center;
        padding: 0 20px;
        //   padding: 10px;
        .clndr-previous-button, .clndr-next-button {
            font-size: 1rem;
            font-weight: 700;

            &:hover {
                cursor: pointer;
            }
        }

        .month {
            font-size: 1rem;
            font-weight: 700;
        }
    }

    .header-days {
        margin-bottom: 20px;
    }

    .header-day {
        font-size: .9rem;
        color: @c-blue--text;
        font-weight: 700;
    }

    .day-contents {
        color: @c-blue--text;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
    }


    td {
        height: 40px;
        text-align: center;


        &:hover {
            cursor: pointer;
        }
    }
    /*#calTitle {
        background: @c-blue--light;
        color: @c-blue--text;

        button {
            background: @c-blue--light;
        }
    }

    #calThead > div {
        font-size: .8rem;
        color: @c-blue--text;
    }

    #calTbody {
        .a-date {
            font-size: .8rem;
            color: @c-blue--text;

            &.current {
                border-color: @c-blue--dark;
            }

            &.event {
                &:before {
                    background-color: @c-blue--dark;
                }
            }
        }
    }

    #calTFooter {
        #eventTitle {
            font-size: .8rem;
        }

        #calLink {
            font-size: .8rem;
            color: @c-blue--text;
            background: none;
        }
    }*/
}
.meetings-listing__empty-message{

}

.days-container {
    position: relative;
    width: 280px;

    .events {
        position: absolute;
        left: 280px;
        width: 280px;
        //height: 217px;
        transition: left 0.5s;
        visibility: hidden;
    }

    .days {
        position: absolute;
        left: 0;
        width: 280px;
        //height: 217px;
        transition: left 0.5s;
    }

    &.show-events {
        .events {
            left: 0;
            visibility: visible;

            .headers {
                display: flex;
                justify-content: space-between;
                padding: 15px;
                // background-color : @c-blue--light;
                color: @c-blue--text;
                font-weight: 700;

                .event-header {
                    border-bottom: #7ac143 solid 3px;
                }
            }
        }

        .days {
            left: 280px;
        }
    }
}
.x-button{
    font-size: 1rem;
    &:hover{
        cursor: pointer;
    }
}
.events-list {
    padding: 15px;

    a {
        color: @c-blue--text;
    }
}
.meetings-listing__button {
    min-width: 80px;
    width: 100%;
  //  margin-left: 7px;
}
.meetings-listing__date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    max-width: 87px;

    .meetings-listing__date-month {
        background: @c-green--medium;
        font-weight: 700;
        width: 100%;
        height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    .meetings-listing__date-year {
        font-weight: 700;
        background: #e6e7e8;
        width: 100%;
        height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .meetings-listing__date-day {
        font-weight: 700;
        width: 100%;
    }
}
.date-divider{
    width: 17px;
    margin: 0 5px;
}
.meetings-listing__calendar-container{
    margin-bottom: 40px;

}
.meetings-listing__listing-col{
    @media @tablet{
        flex: 0 0 100%; 
    }
}