html {
    font-size: 16px;
}

body {
    color: @c-body;
    padding: 0;
    margin: 0;
    font-family: @font-sans-serif;
    font-size: 16px;
   
   
  
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    // overflow-x: hidden;
    flex: 1; 
    // padding-top: @header-height;
}

main {
    flex: 1 0 auto; 
    height: 100%;
}

.container {
    max-width: @container-width;
    width: 100%;
}

.row {
    display: flex;
    flex-wrap: wrap;
    clear:both;
  
    /*.container {
        padding: 0;
    }*/
}

.row-container, .fullwidth {
    // margin-top: @content-padding;
   // margin-bottom: @content-padding;
   margin-bottom:30px;
   clear:both;
    // &:first-of-type {
    //     margin-top: 0;
    // }
}

.column {
    flex: 1;
}
.no-margin{
    margin: 0;

}
.no-padding{
    padding: 0;
}