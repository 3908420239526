
.main-content__container {
    margin-top: 60px;
    .main-content__text{
        text-align: center;

        p{
            line-height: 1.6;

            &:last-of-type{
                margin-bottom: 0;
            }
        }
    }
    &--has-image{
        
        .main-content__text{
            text-align: left;
            p{
                line-height: 1.4;
            }
        }
    }
}

.main-content {

    &--publication {
        text-align: center;
        margin-top: 40px;
    }
}

.main-content__text {

    &--publication{
        margin: 60px 0;
    }

   .intro-text{
       p{
           text-align: center;
           font-size: 1.75rem;
       }
   }

    p {
        font-size: 1.75rem;
        font-weight: 400;
    }
}

.main-content__text--home {

        text-align: center;

        p {
            font-size: 1.75rem;
        }
    
}
.main-content__title {
    margin-top: 15px;
    border-bottom: solid #7ac143 5px;
    color: #065d7c;
    display: inline-block;
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 15px;

    &--publication {
        text-align: center;
        // max-width: 700px;
        margin-bottom: 0;
    }
}