.events__container
{
   
}
.events__list {
   
    padding-left:0;
}
.events__list-item {
   margin:15px 0;

}
.events__list-title{
    font-weight:500;
}
.event-button{
    color: @c-blue--text;
    font-weight: 700;
    &:before{
        color: @c-blue--text;

    }
}
.eventMapPanel{
    margin-bottom: 15px;
}
.upcoming-events__title{
    background-color: @c-blue--light;
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    padding: 10px;
    color: @c-blue--text;
    border: none;
    text-align: center;
    margin-bottom: 0;
}
.event-details__container{
   
}
.event-details__docs {
    margin-bottom: 30px;

    .publication-details__download {
        height: auto;
        width: auto;
    }

    .publication-details__download-text{
        margin-bottom: 15px;
        font-size: 1.1rem;
    }
}