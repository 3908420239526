

.button {
    background-color: @c-green--medium;
    color: @c-blue--text;
    border: 0;
    padding: 10px 20px;
    outline: none;
    text-decoration: none !important;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    display: inline-block;
    min-width: 120px;
    font-size: .75rem;
    text-align: center;
    letter-spacing: 1px;
    cursor: pointer;
    margin: 0 10px 10px 0;
    .transition(.3s);
    &:last-of-type {
        margin-bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        pointer-events: none;
        opacity: 0;
        .transition(.2s);
    }
    &:hover {
        text-decoration: underline;
        color: @c-blue--text;
        // background-color: @c-main;
        &:after {
            opacity: .1;
        }
    }
    &--secondary {
        background-color: @c-blue--dark;
        color: #fff;

        &:hover{
            color: #fff;
        }
    }
    &--ghost {
        background: none;
        border: 2px solid @c-main;
    }
    &--rte {
       // padding: 0;
        /*a {
            padding: 10px 20px;
            color: inherit;
            text-decoration: none;
            display: block;
        }*/
        font-weight: 700;
        width: 180px;
        
    }
}

.cta-button {
    
        width: 100%;
        margin: 10px 0;
        padding: 20px;
        text-transform: none;
        font-size: 1rem;

        /*&:before {
            content: '\f019';
            font-family: 'fontAwesome';
            margin-right: 10px;
        }*/
    
}

.cta-button__icon{
    margin-right: 10px;
}