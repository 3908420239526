.panel--colour {
    .panel-title {
        color: #FFF;
    }
}

.panel {
    &--main {
        background: @c-green--light;
        color: @c-blue--text;

        .feature-panel__content-title {
            color: @c-blue--text;
        }

        .feature-panel__link {
            color: @c-blue--text;
            border: solid 4px @c-blue--text;
        }

        .custom-blockquote__icon {
            background-color: @c-main;
        }

        .button {
            background-color: lighten(@c-main, 10%);
        }
    }

    &--dark-blue {
        background: @c-blue--dark;

        h2 {
            color: #fff;
        }

        .navigation-block__subtitle, .feature-panels__subtitle, .latest-news__content-title, .latest-news__text, .related-content__subtitle, .related-content__content-title, .related-content__text {
            color: #fff;
        }

        .nested-panel__main-link, .latest-news__main-link {
            color: @c-blue--text;
            background: @c-green--medium;
        }

        .latest-news__date {
            background: @c-green--medium;
            color: @c-blue--text;
        }

        .latest-news__link, .related-content__link {
            color: #fff;
            border: solid #fff 4px;
        }
    }

    &--light-blue {
        background: @c-blue--light;
        color: @c-blue--text;

        .feature-panel__content-title {
            color: @c-blue--text;
        }

        .feature-panel__link {
            color: @c-blue--text;
            border: solid 4px @c-blue--text;
        }
    }

    &--dark-grey {
        background: @c-grey--dark;
    }

    &--light-grey {
        background: @c-grey--light;
    }

    &--secondary {
        background: @c-secondary;
        color: #FFF;

        &.quote-panel {
            &:before, &:after {
                display: none;
            }
        }

        .panel-title {
            border-color: #FFF;
        }

        .custom-blockquote__icon {
            background-color: @c-secondary;
        }

        .button {
            background-color: lighten(@c-secondary, 10%);
        }

        .panel__icon {
            background-color: @c-main;
        }
    }

    &--warning {
        background: @c-warning;
        color: #FFF;

        &.quote-panel {
            &:before, &:after {
                display: none;
            }
        }

        .panel-title {
            border-color: #FFF;
        }

        .custom-blockquote__icon {
            background-color: @c-warning;
        }

        .button {
            background-color: lighten(@c-warning, 10%);
        }

        .panel__icon {
            background-color: #FFF;
            color: @c-warning;
        }
    }
}