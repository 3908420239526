.custom-blockquote {
    background-color: #f5f5f5;
    padding: 30px;
    margin: 30px 0 30px 70px;
    position: relative;
    @media @mobile {
        margin: 80px 0 30px 0;
        padding: 20px;
    }
    /*&:last-of-type {
        margin-bottom: 0;
    }*/
    &:before {
        content: '';
        position: absolute;
        top: 20px;
        left: -6px;
        width: 12px;
        height: 12px;
        background-color: inherit;
        transform: rotate(45deg);
        @media @mobile {
            left: 15px;
            top: -6px;
        }
    }
    a {
        color: inherit;
        font-weight: 500;
        text-decoration: underline;
        &:before {
            color: inherit !important;
            text-decoration: none;
        }
    }
}

.custom-blockquote__title {
    margin-bottom: 10px;
}
.custom-blockquote__text {
    p{
        margin-bottom:0;
    }

}

.custom-blockquote__icon {
    position: absolute;
    top: 0;
    left: -70px;
    width: 50px;
    height: 50px;
    background-color: @c-main;
    border-radius: 50%;
    color: #FFF;
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem !important;
    @media @mobile {
        left: 0;
        top: -55px;
        width: 40px;
        height: 40px;
        font-size: 1rem !important;
    }
}