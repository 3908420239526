.umbraco-forms-page {

    h2 { font-size: 1em;}

    input[type=submit] {
        background-color: @c-blue--dark;
        color: #fff;
        border: 0;
        padding: 10px 20px;
        outline: none;
        text-decoration: none !important;
        text-transform: uppercase;
        font-weight: 700;
        position: relative;
        display: inline-block;
        min-width: 120px;
        font-size: .75rem;
        text-align: center;
        letter-spacing: 1px;
        cursor: pointer;
        margin: 0 10px 10px 0;
        border-radius: 0;

        &:hover{
            text-decoration: underline !important;
        }
    }

    .umbraco-forms-container {

        input {
            height: 40px;
            width: 100%;
            color: @c-body;
        }

        select {
            height: 40px;
            width: 100%;
        }

        textarea {
            height: 145px;
        }

        .umbraco-forms-field.shortanswer, .longanswer {
            label {
                display: none;
            }
        }

        .umbraco-forms-field {
            .umbraco-forms-field-wrapper {
                input {
                    max-width: 100%; /* !important;*/
                    width: 100%;
                }

                textarea {
                    max-width: 100%; /*!important;*/
                    width: 100%;
                }
            }
        }
    }
}

.search-label{
    display: none;
}


.interest-form__title{
    font-size: 1.875rem;
    font-weight: 700;
    color: @c-blue--text;
    border: none;
}
.form__button-col{
    display: flex;
    justify-content: center;
}

.feedback-form {
    input[type=submit] {
        background-color: @c-green--medium;
        color: @c-blue--text;
        }
  .umbraco-forms-container{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 -10px;

      .umbraco-forms-field.name,.umbraco-forms-field.email{
          flex-basis: 48%; 
          margin: 0 10px;

          @media @tablet{
             flex-basis: 100%;
          }
      }
      .umbraco-forms-field.dataconsent{
          flex-basis: 100%;
      }
  }
    }


.umbraco-forms-field.recaptcha2{
    margin: auto;
    padding-top: 1em;
}


.DOIForm {
    .umbraco-forms-page .umbraco-forms-container {
        .umbraco-forms-field label.umbraco-forms-label {
            display: block;
        }
        .umbraco-forms-field.shortanswer .umbraco-forms-field-wrapper input {
            max-width: none;
            width: 100%;
        }

        &.col-md-2 .umbraco-forms-field label.umbraco-forms-label {
            display: none;
        }

    }

/*    .umbraco-forms-page .umbraco-forms-container .umbraco-forms-field.shortanswer .umbraco-forms-field-wrapper input {
        max-width: none;
        width: 100%;
    }

    .DOIForm .umbraco-forms-page .umbraco-forms-container.col-md-2 .umbraco-forms-field label.umbraco-forms-label {
        display: none;
    }*/
}
