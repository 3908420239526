
.navbar {
    background-color: @c-green--medium;
    z-index: 100;
    border-radius: 0;
    min-height: 0;
    margin: 0;
    .transition(.3s);
    

    /*@media print {
        display:none; 
    }*/

    @media @tablet-min {
        position: sticky;
        top: 0;
    }

    @media @tablet {
        height:100%; 
        overflow:scroll;
        position: fixed;
        top: @header-height--tablet;
        left: 0;
        right: 0;
        box-shadow: 0 20px 30px -10px rgba(0,0,0,.2);
        transform: translateY(-100%);
    }

    &--active {
        transform:none;
    }

    &--sticky {
        border-bottom: 1px solid #eee;
    }
}

.navbar__container {
    display: flex;
    height: 52px;

    @media @tablet{
        height: auto; 
        margin-bottom: 80px;
    }

    @media @mobile{
        margin-bottom: 150px;
    }
}

.navbar-collapse {
    flex: 1;
    padding: 0;
}

.navbar-nav {
    // width: 100%;
    float: none;
    padding: 20px 0;
    @media @tablet-min {
        display: flex;
        justify-content: space-between;
        margin: 0 -10px 0 0;
        width: 100%;
        width: ~"calc(100% + 20px)";
    }
    @media @tablet {
        margin: 0;
        padding: 0;
    }
    &:before, &:after {
        display: none;
    }
}

.nav__item {
    &.active {
        .nav__link.dropdown-toggle {
            color: @c-main;
            font-weight: 500;

            &:after {
                transform: scaleX(1);
                border-color: @c-main;
            }
        }

        .nav__link {
            border-bottom: solid #fff 5px;
            /*border-bottom: solid #424242 5px;*/
            padding-bottom: 10px;
            padding-top: 17px;
            padding-left: 0;
            padding-right: 0;      
            color: @c-blue--text;
            

            @media @tablet{
                border: none;
                font-weight: 600;
            }
        }
    }

    &-parent {
        &:hover {
            cursor: pointer;

            .nav__dropdown {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
                pointer-events: all;
            }

            .nav__link:not(.active) {
                border-bottom: solid #fff 5px;
                // color: #fff;
                padding-bottom: 9px;
                padding-top: 14px;
                color: @c-blue--text;

                @media @tablet{
                    border: none;
                }
            }
        }

        @media @tablet-min {
            margin: 0 10px;
            display: flex !important;
            align-items: center;
        }

        &:first-of-type {
            margin-left: 0;
        }

        .nav__link {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--dropdown {
        border-bottom: 1px solid #eee;

        &:hover {
            //background-color: lightgray;
        }

        &:last-of-type {
            border-bottom: 0;
        }
    }

    &.open {
        .nav__link {
            &:after {
                transform: scaleX(1);
            }
        }

        .nav__dropdown {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
    }

    &:last-of-type {
        .nav__dropdown {
            left: auto;
            right: -15px;

            &:before {
                left: auto;
                right: 15px;
            }
        }
    }
}
.nav__item.dropdown {

    &:hover{
        &:after{
            transform: rotate(180deg);
        }
    }
    &:after {
        content: "\f0d7";
        font-family: FontAwesome; 
        margin-left: 10px; 
        color: @c-blue--text;
        font-size: 1rem;

    }

    @media @tablet{
        &:after{
            content: none;
        }
    }
}
.home-icon{
  font-size:40px !important;
}

.nav__link {
   // padding: 0 !important;
    // color: #939598;
    color: @c-blue--text;
   // color: #424242;
    font-size: 1.0625rem;
    background: none !important;
    font-weight: 600;
    /*padding-left: 0 !important;
    padding-right: 0 !important;*/

    &:not(.nav__link--dropdown) {
        // font-weight: 600;

        &:hover{
            color: @c-blue--text;
        }
        @media @tablet-min {
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -6px;
                border-bottom: 2px solid lighten(#939598, 30%);
                transform: scaleX(0);
                will-change: transform;
                .transition(.3s);
            }
        }

       
    }

    &:focus {
        color: #939598;
    }

    @media @tablet {
        padding: 10px 15px !important;
    }

    &--dropdown {
        padding: 10px 15px !important;
        font-size: 1rem;
        color: #939598;
    }
}

.nav__dropdown {
    border: 0;
    padding: 0;
    will-change: transform;
    .transition(.3s);
    @media @tablet-min {
        display: block;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
        transform: translateY(-10px);
        top: 45px;
        left: -15px;
        border-radius: 5px !important;
        box-shadow: 0 10px 20px -10px rgba(0,0,0,.5);
        &:after{
            content:'';
            position:absolute;
            left:0;
            right:0;
            top:-30px;
            height:30px;
            
        }
    }
    @media @tablet {
        display:none;
        margin: 0 0 0 20px;
        border-left:  2px solid #eee !important;
        position: relative;
        box-shadow: none;
        border-radius: 0;
        float: none;
    }
    /*&:before {
        content: '';
        height: 15px;
        width: 15px;
        background-color: #FFF;
        transform: rotate(45deg);
        position: absolute;
        top: -5px;
        left: 15px;
        z-index: -1;
    }*/

}

.sticky-wrapper {
    z-index: 100;
    @media @tablet{
       display:none;
    }
}

.navbar-toggle {
    background-color: @c-main;
    border: 2px solid @c-main;
    color:  #FFF;
    padding: 5px 10px;
    width: 37px;
    height: 37px;
    position: relative;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin: 0;
    @media @tablet {
        display: flex;
    }
    &:before, &:after {
        font-family: 'fontAwesome';
        position: absolute;
        .transition(.3s);
        will-change: transform;
    }
    &:before {
        content: '\f00d';
    }
    &:after {
        content: '\f0c9';
        transform: translateY(-25px);
    }
    &.collapsed {
        background-color: #FFF;
        border-color: @c-grey;
        color: @c-body;
        &:before {
            transform: translateY(25px);
        }
        &:after {
            transform: translateY(0);
        }

    }
}

.home-page__link{
    padding:0 !important;
}
.mobile__nav-title{
    display: none;

    @media @tablet{
        display: block;
    }
}
.nav > li > a.mobile__nav-title {
    display: none;

    @media @tablet {
        display: block;

        &:after {
            content: "\f0d7";
            font-family: FontAwesome;
            margin-left: 10px;
            color: @c-blue--text;
            font-size: 1rem;
        }
    }

    &--active {


        &:after {
            @media @tablet {
                transform: rotate(180deg) !important;
            }
        }
    }
}
.nav__overview-link{
    display: none;
    @media @tablet{
        display: block;
    }
}