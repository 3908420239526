

.loading-spinner {
  width: 70px;
  height: 70px;
  position: fixed;
  top: 50vh;
  left: 50vw;
  margin: -35px 0 0 -35px;
  border: 12px solid rgb(187, 187, 187);
  border-right-color: transparent;
  border-radius: 50%;
  transition: all ease .5s;
  animation: spinner linear 1s;
  animation-iteration-count: infinite;
  transition-delay: 0s;
  z-index: 9;
}

.loading-overlay {
  &:before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #FFF;
    z-index: 90;
    .transition(.3s);
    transition-delay: .5s;
  }
  &:after {
    content: '';
    .loading-spinner;
    z-index: 91;
  }
}


.loading-overlay--hide {
  &:before, &:after {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes loading-animation{
   0% {
     transform:  translate(-50%, -50%) rotate(0deg) ;
   }
   100% {
     transform:  translate(-50%, -50%) rotate(360deg) ;
   }
 }