img, iframe {
    display: block;
    max-width: 100%;
}

/*a[target^="_blank"]:not(.footer__link) {
    &:before {
        content: '\f08e';
        font-family: 'fontAwesome';
        color: #666;
        margin-right: 5px;
        margin-left: 5px;
        text-decoration: none;
        font-size: .7rem;
        display: inline-block;
    }
}*/

a[target^="_blank"]:not(.footer__link).event-button {
    &:before {
        content: '\f08e';
        font-family: 'fontAwesome';
        color: @c-blue--text;
        margin-right: 5px;
        margin-left: 0px;
        text-decoration: none;
        font-size: .7rem;
        display: inline-block;
    }
}

.maincontent {
    a[href^="http"]:not(.footer__link) {
        display: inline;
    }

    .main-image {
        float: right;
     //   margin: 0 0 30px 30px;
     //   max-width: 400px;
        // clear: both;
        @media @desktop {
            max-width: none;
            float: none;
            margin: 0 0 30px 0;
            width: 100%;
        }
    }

    .lazy-load {
      //  background-color: #eee;
        // padding-top: 50%;
        // position: relative;
    //    display: block;
    }



    .rte {
        img[style^="float: right"] {
            margin-left: 20px;
            margin-bottom: 20px;
        }
    }

    .rte {
        img[style^="float: left"] {
            margin-right: 20px;
            margin-bottom: 20px;
        }
    }

    .responsive__video {
        float: right;
        margin: 0 0 30px 30px;
    }

    .responsive__video--grid {
        float: none;
        margin: 0 0 30px 0;
    }

    .addthis_inline_share_toolbox {
        margin-top: 30px;
    }
}

.desktop-hide {
    @media @tablet-min {
        display: none !important;
    }
    @media @mobile{ 
        display: block;
    }
}
.tablet-hide{
    @media @tablet{
        display: none !important; 
    }
}
.mobile-hide {
  
    @media @mobile {
        display: none !important;
    }
}
.tablet-hide{
    @media @tablet{
        display: none !important;
    }
}
.desktop-hide--nav {
    @media @tablet-min {
        display: none !important;
    }

    @media @tablet {
        display: block;
    }
}
.responsive-video {
    display: flex;
    justify-content: center;
}
.div[class^="col-md-"] {
    max-width: 100%;
} 