
.meet-the-team {
}

.meet-the-team__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    /*@media @mobile{
        display: block;
    }*/
}

.meet-the-team__panel {
    /*flex: 0 1 218px;*/
    flex: 0 1 ~"calc(20% - 20px)";
    margin: 0 10px 20px;
    // max-width: 238px;
    .transition(.8s);

    /*@media @laptop {
        flex: 0 1 ~"calc(20% - 20px)";
        
            
    }*/

    @media @tablet {
        flex: 0 1 ~"calc(33% - 20px)";
        max-width: none;
    }

    @media @mobile {
        flex: 0 1 ~"calc(50% - 20px)"; 

    }


    &:not(&--active) {
        padding-bottom: 0 !important;
    }

    &--active {
        .meet-the-team__description {
            opacity: 1;
        }

        .meet-the-team__content {
            &:after {
                transform: rotate(45deg);
                animation: team_arrow .5s ease;
                animation-delay: .7s;
                animation-fill-mode: forwards;
            }
        }
    }
}

.meet-the-team__thumbnail {
    width: 100%;
    height: 298px; 
    position: relative;
    cursor: pointer;
    padding: 10px;
    user-select: none;
   // min-width: 240px;
    margin-bottom: 20px;
    @media @mobile{
        height: 200px;
    }
}

.meet-the-team__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.meet-the-team__content {
    position: relative;
    &:after {
        content: '';
        position: absolute;
        background-color: @c-green--light;
        display: block;
        height: 40px;
        left: 50%;
        margin-left: -20px;
        // top: 100px;
        bottom: -80px;
        transform: rotate(45deg);
        width: 40px;
        opacity: 0;
    }
}

.meet-the-team__title {
    position: relative;
    z-index: 3;
    margin-bottom: 0;
    font-weight: 700;
   
    margin-top: 30px;

}

.meet-the-team__subheading {
    margin-bottom: 20px;
    //color: blue;
    font-weight: 600;
    border: none;
    display: block;
    &:not(:first-of-type)
    {
        margin-top: 40px;
    }
}

@keyframes team_arrow {
    0% {
        opacity: 1;
        // top: 100px;
        bottom: -125px;
    }
    30% {
        opacity: 1;
    }
    100% {
        // top: 75px;
        bottom: -80px;
        opacity: 1;
    }
}

.meet-the-team__sub {
    position: relative;
    z-index: 3;
  
}

.meet-the-team__description {
    position: absolute;
    left: @layout-padding;
    right: @layout-padding;
    background: @c-green--light;
    opacity: 0;
    .transition(.8s);
    color: @c-body;
    padding: 65px 0;
    margin-top: 40px;
    &:before {
        content: '';
        position: absolute;
        background-color: inherit;
        display: block;
        left: -30px;
        right: -30px;
        top: 0;
        bottom: 0;
    }
    p{
        max-width: 850px;
        @media @laptop{
            max-width: 550px;
        }
    }
}

.meet-the-team__description-text{
    max-width: 904px;
    z-index: 1;
   
    p{
        font-weight: 600;
    }
}
.meet-the-team__title-main {
    margin-bottom: 50px;
}

.team-description__container{
    display: flex;
}