body.ie {
    .panel-body {
        // display:` block;
    }

    .toolkit__button{
        flex: 1 1 12%;
    }
    .logo{
        height:53px;
    }

    .services__img-container{
        height:286px;
        
    }
}


body.ie--10, body.ie--11 {

}   