.publication-details
{

}
.publication-details__row{

}
.publication-details__col {

    &--text{

    }
    &--downloads{
        display: flex;
        flex-direction: column; 
        align-items: flex-end;
        margin-bottom: 30px;

        @media @mobile{
            margin: 0 auto;
        }
    }
}
.publication-details__section{

}
.publication-details__section-text{
    p{
        color: @c-blue--text;
        font-size: 1.125rem; 

    }
}
.publication-details__section-heading{

}
.publication-details__download {
    background-color: @c-blue--light;
    color: @c-blue--text;
    height: 272px;
    width: 280px;
    padding: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;

    &:not(:first-of-type) {
        margin-top: 20px;
    }

    &--events{
        margin-left: auto;
    }
}
.publication-details__section{
    margin: 30px 0;

    &:first-of-type{
        margin-top: 0;
    }
}
.publication-details__download-icon {
    height: 63px;
    margin: 0 auto;
    margin-bottom: 30px;
}
.publication-details__download-text {
    font-weight:700; 
    font-size: 1.25rem;
}
.publication-details__download-link {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: 0.75rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    border: solid @c-blue--text 4px;
    color: @c-blue--text;
    padding: 10px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-height: 40px;
    margin-top: auto;

    &:hover {
        background-color: #fff;
        color: @c-blue--dark;
    }
}
.publication-page__col {
    padding: 0 10px;

   
    &:not(:last-of-type) {
        border-right: solid 1px grey;

        @media @mobile{
            border: none;
        }
    }

    &--title {
        flex: 0 0 41%;
        // width: 42%;
        @media @mobile {
            flex: 0 0 100%;
        }
    }

    &--ref {
        flex: 0 0 21%;
        //   width: 19%;
        @media @mobile {
            flex: 0 0 100%;
        }
    }

    &--specialty {
        //   width: 19%;
        flex: 0 0 21%;

        @media @mobile {
            flex: 0 0 100%;
        }
    }

    &--date {
        // width: 13%;
        flex: 0 0 17%;

        @media @mobile {
            flex: 0 0 100%;
        }
    }
}
.publication-page__item-title {
    font-weight: 600;

    @media @mobile {
        padding: 10px 0;
    }
    // margin-left: -10px;
}

.publication-page__item-ref {
    // padding: 0 10px;
    @media @mobile {
        padding: 10px 0;
    }
}

.publication-page__item-topic {
    //padding: 0 10px;
    @media @mobile {
        padding: 10px 0;
    }
}

.publication-page__item-date {
    // padding: 0 15px;
    margin-left: 5px;

    @media @mobile {
        padding: 10px 0;
    }
}
.publication-page__list {
    border-bottom: solid 1px #929497;
}