.homepage-block__row{

}
.homepage-block__latest-advice{
    max-width: 100%;
}
.homepage-block__links {
    display: flex;
    flex-direction: column;
    max-width: 100%;

    @media @tablet{
        margin: 0 auto;
        margin-top: 20px;
        width: 100%;

    }
  
  

}
.homepage-block__link-container {
    display: flex;
    justify-content: space-between;
    background-color: @c-blue--light;
    padding: 15px 25px;
    align-items: center;
    margin-bottom: 10px;
    flex-grow: 1;
    position: relative;

    @media @mobile {
        flex-direction: column;
    }

    &:first-of-type {
        background-color: @c-blue--dark;
        color: #fff;

        .homepage-block__link-title {
            color: #fff;
        }

        .homepage-block__link {
            color: #fff;
            border: solid #fff 4px;
        }
    }

    .homepage-block__link-icon {
        width: 50px;

        
            margin-right: 32px;
            flex-basis: 5%;
        
    }

    .homepage-block__link-title {
        font-weight: 700;
        font-size: 1.575rem;
        border: none;
        color: @c-blue--text;
        margin: 0;

            flex-basis: 65%;
            @media @mobile{
                text-align: center;
            }
        
    }

    .homepage-block__link {
        align-items: center;
        border: solid @c-blue--text 4px;
        color: @c-blue--text;
        display: flex;
        font-size: .75rem;
        font-weight: 700;
        justify-content: center;
        height: 40px;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        width: 180px;

      
            flex-basis: 30%;
            
        }
    }

    .homepage-block__search {
        background-color: @c-green--light;
        padding: 15px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;

        @media @mobile {
            flex-direction: column;
        }

        .header__search-submit {
            background-color: transparent;
            margin-left: 30px;
            outline: none;

            &:after {
                background-color: transparent;
                font-size: 2.5rem;
            }

            &:hover {
                background-color: lighten(@c-green--light,5%);
            }
        }
    }

    .homepage-block__search-title {
        font-weight: 700;
        font-size: 1.575rem;
        border: none;
        color: @c-blue--text;
        margin: 0;
    }

    .homepage-block__search-box {
        height: 40px;
        width: 400px;
        outline: none;
        border: none;

        @media @mobile {
            width: 100%;
        }
    }

    .homepage-block__search-icon {
    }

    .homepage-block__news {
        background-color: #f1f1f2;
        /*height: 200px;*/
        text-align: center;
        padding: 15px;
        position: relative;
        min-height: 210px;

        .homepage-block__news-list, .owl-stage-outer, .owl-stage, .owl-item, .homepage-block__news-item, .homepage-block__news-content {
            height: 100%;
        }

        .owl-stage-outer {
            height: 100%;
        }

        .owl-nav {
            .owl-prev {
                position: absolute;
                left: 0;
                top: 45%;
                border: none;
                outline: none;

                @media @mobile {
                    left: -15px
                }
            }

            .owl-next {
                position: absolute;
                right: 0;
                top: 45%;
                border: none;
                outline: none;

                @media @mobile {
                    right: -15px;
                }
            }
        }
    }

    .homepage-block__news-nav {
        width: 13px;
    }

    .homepage-block__news-list {
    }

    .homepage-block__news-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .homepage-block__news-item {
    }

    .homepage-block__news-title {
        margin-bottom: 10px;
    }

    .homepage-block__news-date {
        font-weight: 700;
        font-size: 0.875rem;
        color: @c-blue--text;
        margin-bottom: 5px;
    }

    .homepage-block__news-text {
        max-width: 350px;
        margin: 0 auto;
        font-size: 1rem;
        color: @c-blue--text;
        font-weight: 600;
        margin-bottom: 10px;
        /*max-height: 20px;*/

        @media @mobile {
            max-width: 290px;
        }
    }

    .homepage-block__news-link {
        align-items: center;
        background: #bde0a3;
        color: @c-blue--text;
        display: flex;
        font-size: .75rem;
        font-weight: 700;
        justify-content: center;
        margin: 15px auto;
        //margin-top: 50px;
        min-height: 40px;
        padding: 10px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: 180px;
    }

    #homepageBlockForm {
        display: flex;
        align-items: center;
    }