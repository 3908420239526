.featured-content__row
{
    &--reverse{
        flex-direction: row-reverse;
    }
}
.featured-content__text-panel {
    background-color: @c-grey--light;

    @media @tablet {
        flex: 0 0 50%;
 
    }
    @media @mobile{
        flex: 0 0 100%;
    }
}
.featured-content__text-container {
    padding: 40px;
    span{
        font-size: 1.4rem;
    }
    p {
        span {
            font-size: 1.4rem;
        }
    }
}
.featured-content__img-panel {
    @media @tablet {
        flex: 0 0 50%;
        display: flex;
        align-items: center;
        background: @c-grey--light;
    }
    @media @mobile{
         flex: 0 0 100%;
    }
}
.featured-content__container {
    // margin: 0;
    padding: 50px 15px;
   // margin: 2.5px auto;

    &--no-padding {
        padding: 0;
      //  margin: 2.5px;
    }

    &--no-top {
        padding-top: 0;
       // padding-bottom: 50px;
    }

    &--no-bottom {
     //   padding-top: 50px;
        padding-bottom: 0;
    }
    /*&:last-of-type {
        .nested-panel--featured-content {
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
    }

    &:first-of-type {
        .nested-panel--featured-content {
            margin-top: 20px;
            padding-top: 20px;
        }
    }*/
}