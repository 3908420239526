.related-content__intro {
    text-align: center;
}

h2.related-content__title {
    margin-bottom: 0px;
}
.related-content__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    //   background-image: url("/img/Placeholders/TestImage.png");
    background-image: url("/img/PanelOverlay-Cropped.png");
    background-size: cover;
    background-position: center;
}

.related-content__subtitle {
    margin: 0 auto;
    margin-top: 50px;
    max-width: 900px;

    p {
        margin: 0 auto;
        max-width: 900px;
        font-size: 1.625rem;
        font-size: 600;
    }
}

.related-content {
    margin-top: 20px;
}

.related-content__list {
display: flex;
flex-wrap: wrap;
}
.related-content__item {
    flex: 0 0 33.3333%;
    //background-color: @c-blue--dark;
    padding: 30px;
    display: flex;
    position: relative;
    // min-height: 363px;
    @media @tablet {
        flex: 0 0 100%;
        margin-top: 10px;
    }
}
.related-content__content {
    text-align: center;
    color: @c-blue--text;
    display: flex;
    flex-direction: column;
    z-index: 2;
    width: 100%;
}

.related-content__icon {
    height: 50px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.related-content__content-title {
    font-weight: 700;
    line-height: 1.2;
    color: @c-blue--text;
    border: none;
    font-size: 1.875rem;
}

.related-content__text {
    margin-bottom: 40px;

    p {
    }
}

.related-content__link {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: 0.75rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    border: solid @c-blue--text 4px;
    color: @c-blue--text;
    padding: 10px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-height: 40px;
    margin-top: auto;

    &:hover {
        // background-color: #fff;
        color: @c-blue--dark;
    }
}