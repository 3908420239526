.toolkit__button-group {
    display: flex;
    justify-content: space-between;
    margin: 20px -10px;
    flex-wrap: wrap;
}

.toolkit__button {
    background-color: #939598;
    color: #FFF;
    flex: 1 1 14%;
    margin: 10px;
    padding: 15px;
    display: inline-flex;
    text-transform: uppercase;
    font-weight: 500;
    font-size: .9rem;
    position: relative;
    .transition(.3s);
    @media @desktop {
        flex: 1 1 30%;
    }
    @media @mobile {
        flex: 1 1 100%;
    }
    &:hover {
        &:not(.active) {
            background-color: lighten(#939598, 10%);
        }
    }
    &:focus, &:hover {
        color: #FFF;
        text-decoration: none;
    }
    &.active {
        background-color: @c-main;

    }
}

.toolkit__button-icon {
    height: 30px;
    // height: 25px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
}

.toolkit__col {
    @media @mobile {
        width: 100%;
    }
}

.toolkit__icon-list{
    display:flex;
    margin-top:15px;
    /*flex:100%;*/
    z-index:1000;
}
.toolkit__icon{
    width:20px;
    height:20px;
    
   
}
.toolkit__icon-background {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 5px;
    background-color: #71B0C7;
    display: flex;
    align-items: center;
    justify-content:center;
    margin-right:10px;
    margin-bottom:10px;
   
}