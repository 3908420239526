.feature-panels__intro{
    text-align: center;
    margin-bottom: 75px;
}
h2.feature-panels__title {
  
    margin-bottom: 0px;
}
.feature-panels__subtitle {
    margin: 0 auto;
    margin-top: 50px;
    max-width: 900px;

    p {
        font-size: 1.625rem;
        font-size: 600;
    }
}
.feature-panels{
   // margin-top: 75px;
}
.feature-panels__list{
   
    
}
.feature-panels__item {
width: 100%;
margin-top: 5px;
background-color: @c-blue--dark;



&--reverse{
    flex-direction: row-reverse;
}
   
}
.feature-panel__content {
    padding: 30px;
    text-align: center;
    color: #fff;
    /*position: relative;*/
    display: flex;
    flex-direction: column;
    position: relative;
    @media @tablet {
        flex: 0 0 50%;
    }

    @media @mobile {
        flex: 0 0 100%;
    }
}
.feature-panel__img-container {
    background: @c-grey--light;
    display: flex;
    align-items: center;

    @media @tablet {
        flex: 0 0 50%;
    }

    @media @mobile {
        flex: 0 0 100%;
    }
} 
.feature-panel__img{
   

}
.feature-panel__icon {
    height: 50px;
    margin: 0 auto;
    margin-bottom: 10px;
}
.feature-panel__content-title {
    font-weight: 700;
    margin-bottom: 20px;
    margin: 0 auto;
    max-width: 300px;
    line-height: 1.2;
    color: #fff;
    border: none;
}
.feature-panel__text{
    margin: 0 auto;
    max-width: 300px;
    p{

    }
}
.feature-panel__link {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    font-size: 0.75rem;
    text-align: center;
    text-transform: uppercase;
    border: solid #fff 4px;
    color: #fff;
    padding: 10px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    min-height: 40px;
    margin-top: auto;
    /*position: absolute;
    bottom: 9%;
    right: 32%;*/
    &:hover {
        background-color: #fff;
        color: @c-blue--dark;
    }
}