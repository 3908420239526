.transition (@duration) {
	transition: all ease @duration;
}


.link--cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	text-indent: -999999px;
}

.text-align--left {
	text-align: left;
}

.no-padding {
	padding: 0;
}

.no-margin {
	margin: 0;
}

.no-scroll {
	overflow: hidden;
}

.margin-bottom {
	margin-bottom: 30px;
}

.margin-top {
	margin-top: 30px !important;
}

.spacer {
	display: block;
	margin: 80px 0;
}

.word-break {
	word-break: break-word;
}

.gradient-background {
	background : linear-gradient(-90deg, rgba(255, 255, 255, 1) 0%, rgba(111, 43, 140, 1) 0%, rgba(107, 48, 143, 1) 17.24%, rgba(94, 61, 152, 1) 36.35%, rgba(74, 83, 167, 1) 56.35%, rgba(45, 114, 188, 1) 76.95%, rgba(8, 153, 215, 1) 97.85%, rgba(4, 158, 218, 1) 100%);
}

.gradient-strip {
	&:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 10px;
		background : linear-gradient(90deg, rgba(111, 177, 200, 1) 0%, rgba(114, 145, 182, 1) 19.44%, rgba(119, 88, 150, 1) 57.07%, rgba(123, 52, 130, 1) 85.01%, rgba(124, 39, 122, 1) 99.96%);
		z-index: 3;
    }
}

.gradient-strip--bottom {
	&:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        height: 10px;
		background : linear-gradient(90deg, rgba(111, 177, 200, 1) 0%, rgba(114, 145, 182, 1) 19.44%, rgba(119, 88, 150, 1) 57.07%, rgba(123, 52, 130, 1) 85.01%, rgba(124, 39, 122, 1) 99.96%);
		z-index: 3;
	}
}

.background-texture {
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url(/img/banners/newBackground.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        opacity: .3;
    }
}